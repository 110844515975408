import React from 'react'
import styled from 'styled-components'

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`

export default ({ url }) => (
  <IFrame
    width="600"
    height="500"
    id="gmap_canvas"
    frameborder="0"
    scrolling="no"
    marginheight="0"
    marginwidth="0"
    src={url}
    />
)
