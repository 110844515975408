import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from 'styled-components'

import CardList from '~/components/CardList'
import Map from '~/components/Map'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 2vmin;
`
const Box = styled.div`
`

const Container = styled.div`
  padding: 40px 20px;
  background-color: ${({ id, theme }) => id === 'servizi' && theme.colors.blue};
  color: ${({ id, theme }) => id === 'servizi' && theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.lightgray};
`

const MaxWidthWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  ${({ id }) => id !== 'servizi' && 'padding: 50px 0;'}
`

const Title = styled.h2`
  line-height: 1.2;
  font-weight: 700;
  ${({ id }) => id === 'servizi' && 'text-align: center;'}
  ${({ id }) => id === 'servizi' && 'font-size: 3em;'}
`

export default ({ page }) => {
  if (page.map) return (
    <Container id={page.slug}>
      <Grid>
        <Box>
          <Map url={page.map.url} />
        </Box>
        <Box>
          <Title>{page.title}</Title>
          {documentToReactComponents(page.content.json)}
        </Box>
      </Grid>
    </Container>
  )

  return (
    <Container id={page.slug}>
      <MaxWidthWrapper id={page.slug}>
        {page.title !== 'Home' &&
          <Title id={page.slug}>
            {page.title}
          </Title>
        }
        {page.content && documentToReactComponents(page.content.json)}
      </MaxWidthWrapper>
      {page.services && <CardList services={page.services} />}
      {page.map && <Map url={page.map.url} />}
    </Container>
  )
}
