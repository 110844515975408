import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '~/components/Layout'
import Hero from '~/components/Hero'
import Section from '~/components/Section'
import Form from '~/components/Form'

export default ({ data, location }) => {
  const [message] = get(data, 'allContentfulMessage.edges')
  const siteMetadata = get(data, 'site.siteMetadata')
  const pages = get(data, 'allContentfulPage.edges')
    .map(({ node }) => node)

  const [metadata] = get(data, 'allContentfulMetadata.edges')

  const navPages = [...pages]
  navPages.push({ title: 'Contattaci', slug: 'contatti' })

  return (
    <Layout
      location={location}
      title={siteMetadata.title}
      url={siteMetadata.url}
      pages={navPages}
      metadata={metadata.node}
      message={message}
    >
      <Hero location={location} data={metadata.node} />
      {pages.map(page => <Section page={page} />)}
      <Form />
    </Layout>
  )
}

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        url
      }
    }
    allContentfulPage(sort: {fields: position}) {
      edges {
        node {
          title
          slug
          content {
            json
          }
          services {
            title
            slug
            heroImage {
              title
              fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
                ...GatsbyContentfulFluid
              }
            }
            body {
              json
            }
          }
          map {
            url
          }
        }
      }
    }
    allContentfulMessage {
      edges {
        node {
          title
          content {
            json
          }
          active
        }
      }
    }
    allContentfulMetadata {
      edges {
        node {
          name
          body {
            json
          }
          text {
            text
          }
          keywords
          email
          phone
          cell
          fax
          address
          iva
          cf
          heroImage: image {
            title
            fluid(
              maxWidth: 1180,
              maxHeight: 480,
              resizingBehavior: FILL
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
