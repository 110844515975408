import React from 'react'
import styled from 'styled-components'

import Card from '~/components/Card'

const List = styled.ul`
  margin: 0;
  padding: 50px 20px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 5vmin;
`

export default ({ services }) => (
  <List>
    {services.map(service => (
      <li key={service.slug}>
        <Card article={service} />
      </li>
    ))}
  </List>
)
