import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import config from '~/config'

const PreviewCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white };
  border: solid 8px ${({ theme }) => theme.colors.white };
  text-align: center;
  cursor: pointer;

  :hover {
    animation: scale-up-center 0.4s ease-in-out both;
  }

  @keyframes scale-up-center {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.05);
    }
  }
`

const PreviewTitle = styled.h3`
  margin: 0;
  padding: 10px;
  font-size: 1.6em;
  line-height: 1.2;
  font-weight: 700;
`

export default ({ article }) => (
  <PreviewCard>
    <Link to={`/${config.service.url}/${article.slug}`}>
      <Img alt={article.heroImage.title} fluid={article.heroImage.fluid} />
      <PreviewTitle>
        {article.title}
      </PreviewTitle>
    </Link>
  </PreviewCard>
)
