import React, { Component } from 'react'
import styled from 'styled-components'
import { Form, Checkbox, Message } from 'semantic-ui-react'
import { string } from 'yup'

const encode = (data) => {
  return Object.keys(data)
    .filter(key => data[key].length)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const StyledForm = styled(Form)`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.blue};
  border: solid 1px ${({ theme }) => theme.colors.lightgray};
  color: ${({ theme }) => theme.colors.white};

  label {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`

const Title = styled.h2`
  text-align: center;
  padding-bottom: 20px;
`

const AcceptCheckbox = styled(Form.Checkbox)`
  height: 25px;
  .checkbox {
    display: block;
  }
`

const PrivacyLink = styled.a`
  color: #6ab5ff;
`

const emailSchema = string().email()

const validateEmail = value => emailSchema.isValid(value)

class ContactForm extends Component {
  state = {
    name: '',
    surname: '',
    email: '',
    telephone: '',
    message: '',
    loading: false,
    success: false,
    error: false,
    validate: true,
    acceptConditions: false,
  }

  handleSubmit = async e => {
    this.setState({ loading: true })
    try {
      const { loading, success, error, validate, acceptConditions, ...data } = this.state
      if (!acceptConditions) return
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contatti', ...data })
      })

      this.setState({
        success: true,
        loading: false,
      })
    } catch (error) {
      this.setState({
        error,
        loading: false
      })
    }

    e.preventDefault();
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  handleBlur = async ({ target }) => {
    if (target.value !== '') {
      const validate = await validateEmail(target.value)
      this.setState({ validate })
    }
  }

  handleEmailChange = async ({ target }) => {
    const { validate } = this.state
    if (!validate) {
      // validate = await validateEmail(target.value)
      this.setState({ validate: await validateEmail(target.value) })
    }
    this.handleChange({ target })
  }

  toggleAcceptedConditions = ({ target }) => {
    const { acceptConditions } = this.state
    this.setState({ acceptConditions: !acceptConditions })
  }

  render() {
    const { loading, success, error, validate, acceptConditions } = this.state

    return (
      <StyledForm
        id="contatti"
        onSubmit={this.handleSubmit}
        name="contatti"
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        loading={loading}
        success={success}
        error={error}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contatti" />
        <Title>
          Contattaci
        </Title>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            name="name"
            label="Nome"
            placeholder="Nome"
            onChange={this.handleChange}
          />
          <Form.Input
            fluid
            name="surname"
            label="Cognome"
            placeholder="Cognome"
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            name="email"
            label="E-mail"
            placeholder="E-mail"
            onChange={this.handleEmailChange}
            onBlur={this.handleBlur}
            error={validate ? null : "L'email non è valida"}
            required
          />
          <Form.Input
            fluid
            name="telephone"
            label="Telefono"
            placeholder="Telefono"
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.TextArea
          name="message"
          label="Messaggio"
          onChange={this.handleChange}
          required
        />
        <Form.Field required>
          <AcceptCheckbox
            checked={acceptConditions}
            onChange={this.toggleAcceptedConditions}
            label={<label>Accetto l'utilizzo dei dati ai fini dell' <PrivacyLink href="/privacy" target="__blank">informativa sulla privacy</PrivacyLink></label>}
            error={acceptConditions ? null : "Accetta le condizioni di trattamento dei dati personali"}
          />
        </Form.Field>
        <Message
          success
          header="Messaggio inviato"
          content="Il messaggio è stato inviato correttamente."
        />
        <Message
          error
          header="Errore"
          content="Si è verificato un errore nell'invio del messaggio. Riprova in un secondo momento o contattaci telefonicamente."
        />
        {!success &&
          <Form.Button
            disabled={!validate || !acceptConditions || success}
            type="submit"
            value="submit"
          >
            Invia
          </Form.Button>
        }
      </StyledForm>
    )
  }
}

export default ContactForm
